import React, { useState } from "react";
import moment from "moment-timezone";
import { rgba } from "polished";

import {
  FlexCol,
  FlexGrid,
  RadioButton,
  ScheduleTable,
  SectionHeader,
  SectionWrapper,
  Text
} from "src/components";

const options = [
  { label: "All", value: "all" },
  { label: "Cosmetics", value: "cosmetics" },
  { label: "Jewelry", value: "jewelry" },
  { label: "Body Art", value: "body art" },
  { label: "Home Items", value: "home items" },
  { label: "Healing", value: "healing" },
  { label: "Instruments", value: "instruments" }
];

function Artisans({ heading, subtext, times, artisans }) {
  const [filter, setFilter] = useState("all");

  let formattedItems = [];
  if (times) {
    times.forEach(time => {
      formattedItems.push({
        label: moment(time.day).format("dddd, MMMM D"),
        ...time
      });
    });
  }
  return (
    <>
      <SectionWrapper
        id={heading.replace(/\s/g, "-").toLowerCase()}
        py={[8, 9, 10]}
        bg="bg.default"
        css={`
          border-top: 1px solid ${props => props.theme.colors.bg.alt};
        `}
      >
        <SectionHeader heading={heading} body={subtext} />
        <div
          css={`
            margin-bottom: ${props => props.theme.space[5] + "px"};
          `}
        >
          <RadioButton
            options={options}
            value={filter}
            valueProp="value"
            labelProp="label"
            onChange={setFilter}
          />
        </div>
        {artisans && (
          <FlexGrid gutterX={[1]} gutterY={[1]}>
            {artisans
              .sort((a, b) => {
                if (
                  (a.artisan_name && a.artisan_name.replace("The ", "")) <
                  (b.artisan_name && b.artisan_name.replace("The ", ""))
                )
                  return -1;
                if (
                  (a.artisan_name && a.artisan_name.replace("The ", "")) >
                  (b.artisan_name && b.artisan_name.replace("The ", ""))
                )
                  return 1;
                return 0;
              })
              .map((artisan, index) => {
                if (
                  artisan.artisan_category.toLowerCase() === filter ||
                  filter === "all"
                ) {
                  return (
                    <FlexCol
                      key={"artisan" + artisan.artisan_name + index}
                      width={[1 / 2, 1 / 3, 1 / 4]}
                    >
                      <div
                        css={`
                          height: 100%;
                          padding: ${props => props.theme.space[3] + "px"};
                          background-color: ${props =>
                            rgba(props.theme.colors.bg.reverse, 1 / 20)};
                        `}
                      >
                        <Text
                          as={
                            artisan.artisan_website_link &&
                            artisan.artisan_website_link.url &&
                            "a"
                          }
                          href={
                            artisan.artisan_website_link &&
                            artisan.artisan_website_link.url &&
                            artisan.artisan_website_link.url
                          }
                          target={
                            artisan.artisan_website_link &&
                            artisan.artisan_website_link.url &&
                            "_blank"
                          }
                          rel={
                            artisan.artisan_website_link &&
                            artisan.artisan_website_link.url &&
                            "noopener noreferrer"
                          }
                        >
                          {artisan.artisan_name}
                        </Text>
                      </div>
                    </FlexCol>
                  );
                } else {
                  return null;
                }
              })}
          </FlexGrid>
        )}
        {formattedItems && (
          <div
            css={`
              margin-top: ${props => props.theme.space[6] + "px"};
            `}
          >
            <ScheduleTable
              heading={"Artisan Market Hours"}
              items={formattedItems}
            />
          </div>
        )}
      </SectionWrapper>
    </>
  );
}

export default Artisans;
