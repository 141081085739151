import React, { useState, useEffect } from "react";
import { RichText as PrismicRichText } from "prismic-reactjs";

import { linkResolver, serializer } from "src/utils";

import {
  Accordion,
  PrismicImage,
  RichText,
  SectionHeader,
  SectionWrapper
} from "src/components";

function Zones({ heading, subtext, zones }) {
  const [formattedZones, setFormattedZones] = useState([]);

  useEffect(() => {
    let newZones = [];
    if (zones) {
      zones.forEach((zone, index) => {
        newZones.push({
          // image: zone.zone_image && zone.zone_image.url && zone.zone_image,
          heading: zone.zone_name && PrismicRichText.asText(zone.zone_name),
          children: (
            <div
              css={`
                display: flex;
                flex-direction: column;
                ${props => props.theme.mediaQueries.small} {
                  flex-direction: row;
                }
              `}
            >
              <div
                css={`
                  flex: 1;
                  ${props => props.theme.mediaQueries.small} {
                    margin-right: 64px;
                  }
                `}
              >
                <RichText
                  children={
                    zone.zone_description &&
                    PrismicRichText.render(
                      zone.zone_description,
                      linkResolver,
                      serializer
                    )
                  }
                  fontSize={[1, 2]}
                  color="text.reverseBody"
                />
              </div>
              {zone.zone_image && zone.zone_image.url && (
                <div
                  css={`
                    width: 100%;
                    margin-top: ${props => props.theme.space[7] + "px"};
                    ${props => props.theme.mediaQueries.small} {
                      width: 33%;
                      margin-top: 0;
                    }
                  `}
                >
                  <img
                    src={zone.zone_image.url}
                    alt={zone.zone_image.alt}
                    height="auto"
                    width="100%"
                  />
                </div>
              )}
            </div>
          ),
          id: "zone" + index
        });
      });
    }
    setFormattedZones(newZones);
  }, [zones]);
  return (
    <>
      <SectionWrapper
        id={heading.replace(/\s/g, "-").toLowerCase()}
        py={[8, 9, 10]}
        bg="bg.reverse"
      >
        <SectionHeader heading={heading} body={subtext} invert={true} />
        <Accordion items={formattedZones} color="text.reverse" />
      </SectionWrapper>
    </>
  );
}

export default Zones;
