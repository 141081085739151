import React from "react";
import { Link } from "gatsby";
import moment from "moment-timezone";

import {
  Box,
  Button,
  PrismicImage,
  ScheduleTable,
  SectionWrapper,
  IlloWrapper,
} from "src/components";

function Intro({ festivalId, mapImage, mapButtonActive, times, ...props }) {
  let formattedItems = [];
  if (times) {
    times.forEach((time) => {
      formattedItems.push({
        label: moment(time.day).format("dddd, MMMM D"),
        ...time,
      });
    });
  }

  return (
    <>
      <div
        css={`
          position: relative;
          z-index: 0;
          background-color: ${(props) => props.theme.colors.brand.primary};
          &::after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            height: 50%;
            background-color: ${(props) => props.theme.colors.bg.default};
          }
        `}
      >
        <SectionWrapper maxWidth={0}>
          {mapImage && mapImage.url && (
            <IlloWrapper {...props}>
              <div
                css={`
                  position: relative;
                  text-align: center;
                `}
              >
                <Box
                  as={mapButtonActive ? Link : "span"}
                  to={mapButtonActive ? "/" + festivalId + "/map" : ""}
                  style={{
                    width: "50vw",
                  }}
                >
                  <PrismicImage image={mapImage} />
                </Box>
                {/* TEMP CHANGE FOR SUMMER SERENADES */}
                {/* {mapButtonActive && (
                  <div
                    css={`
                      position: absolute;
                      bottom: 32px;
                      right: 32px;
                    `}
                  >
                    <Button
                      children={`Open map`}
                      as={Link}
                      to={"/" + festivalId + "/map"}
                      variant="reverseAlt"
                    />
                  </div>
                )} */}
              </div>
            </IlloWrapper>
          )}
        </SectionWrapper>
      </div>
      {/* {formattedItems && formattedItems[0] && (
        <SectionWrapper pb={[8, 9, 10]}>
          <div
            css={`
              margin-top: ${props => props.theme.space[6] + "px"};
            `}
          >
            <ScheduleTable heading={`Gate Times`} items={formattedItems} />
          </div>
        </SectionWrapper>
      )} */}
    </>
  );
}

export default Intro;
