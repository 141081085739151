import React from "react";
import { ThemeProvider } from "styled-components";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import { lighten, rgba } from "polished";

import { SEO, Theme, PageHeaderV2, RibbonCTA } from "src/components";

import Intro from "./_components/_intro";
import Vendors from "./_components/_vendors";
import Artisans from "./_components/_artisans";
import Zones from "./_components/_zones";
import Areas from "./_components/_areas";

const FestivalAtPage = ({ data, setInvert, setInvertLogo }) => {
  let festival = {};
  if (data.festival) {
    if (data.festival.dataString) {
      festival = JSON.parse(data.festival.dataString);
      festival.uid = data.festival.uid;
    }
  }

  return (
    <>
      <SEO
        title={"At " + (festival.title && RichText.asText(festival.title))}
        description={
          festival.opengraph_description &&
          RichText.asText(festival.opengraph_description)
        }
        image={
          festival.opengraph_image &&
          festival.opengraph_image.url &&
          festival.opengraph_image.url
        }
        imageAlt={
          festival.opengraph_image &&
          festival.opengraph_image.alt &&
          festival.opengraph_image.alt
        }
      />
      <ThemeProvider
        theme={Theme({
          bg:
            festival.theme === "Dark theme"
              ? {
                  default: "#1B1A19",
                  alt: lighten(0.05, "#1B1A19"),
                  wash: lighten(0.1, "#1B1A19"),
                  reverse: "#FFF",
                }
              : undefined,
          primary: festival.festival_color,
          secondary: festival.festival_secondary_color,
          tertiary: festival.festival_tertiary_color,
        })}
      >
        {festival.at_the_festival_heading &&
          festival.at_the_festival_heading[0] && (
            <PageHeaderV2
              heading={
                festival.at_the_festival_heading &&
                RichText.asText(festival.at_the_festival_heading)
              }
              links={[
                festival.areas_heading &&
                  festival.areas_heading[0] &&
                  festival.areas_heading[0].text,
                festival.artisans_heading &&
                  festival.artisans_heading[0] &&
                  festival.artisans_heading[0].text,
                festival.zones_heading &&
                  festival.zones_heading[0] &&
                  festival.zones_heading[0].text,

                festival.vendors_heading &&
                  festival.vendors_heading[0] &&
                  festival.vendors_heading[0].text,
              ]}
              color="brand.primary"
              invert={true}
              setInvertLogo={setInvertLogo}
              setInvert={setInvert}
            />
          )}
      </ThemeProvider>
      <ThemeProvider
        theme={Theme({
          text:
            festival.theme === "Dark theme"
              ? {
                  default: "#fff",
                  body: rgba("#fff", 0.75),
                  alt: rgba("#fff", 0.5),
                  reverse: "#1B1A19",
                  reverseBody: rgba("#1B1A19", 0.75),
                  reverseAlt: rgba("#1B1A19", 0.5),
                }
              : undefined,
          bg:
            festival.theme === "Dark theme"
              ? {
                  default: "#1B1A19",
                  alt: lighten(0.05, "#1B1A19"),
                  wash: lighten(0.1, "#1B1A19"),
                  reverse: "#FFF",
                }
              : undefined,
          primary: festival.festival_color,
          secondary: festival.festival_secondary_color,
          tertiary: festival.festival_tertiary_color,
        })}
      >
        <>
          {(festival.map_image ||
            (festival.gate_times && festival.gate_times[0])) && (
            <Intro
              festivalId={festival.uid}
              times={festival.gate_times}
              mapImage={festival.map_image}
              mapButtonActive={
                festival.map_inner_image && festival.map_inner_image.url
              }
              illustrations={festival.illlustrations}
              sw={3}
              se={4}
              e={1}
              size={2.5}
            />
          )}
          {festival.areas_heading &&
            festival.areas_heading[0] &&
            festival.areas_heading[0].text && (
              <Areas
                heading={
                  festival.areas_heading &&
                  RichText.asText(festival.areas_heading)
                }
                areas={festival.areas_of_interest}
              />
            )}
          {festival.artisans_heading &&
            festival.artisans_heading[0] &&
            festival.artisans_heading[0].text && (
              <Artisans
                heading={
                  festival.artisans_heading &&
                  RichText.asText(festival.artisans_heading)
                }
                subtext={festival.artisans_subtext}
                times={festival.artisan_times}
                artisans={festival.artisans}
              />
            )}
          {festival.zones_heading &&
            festival.zones_heading[0] &&
            festival.zones_heading[0].text && (
              <Zones
                heading={
                  festival.zones_heading &&
                  RichText.asText(festival.zones_heading)
                }
                subtext={festival.zones_subtext}
                zones={festival.zones}
              />
            )}
          {festival.vendors_heading &&
            festival.vendors_heading[0] &&
            festival.vendors_heading[0].text && (
              <Vendors
                heading={
                  festival.vendors_heading &&
                  RichText.asText(festival.vendors_heading)
                }
                subtext={festival.vendors_subtext}
                times={festival.vendor_times}
                vendors={festival.body}
              />
            )}
          {festival.at_the_festival_cta_heading &&
            festival.at_the_festival_cta_heading[0] &&
            festival.at_the_festival_cta_heading[0].text && (
              <RibbonCTA
                heading={
                  festival.at_the_festival_cta_heading &&
                  RichText.asText(festival.at_the_festival_cta_heading)
                }
                body={festival.at_the_festival_cta_subheading}
                buttonText={festival.at_the_festival_cta_button_text}
                buttonLink={festival.at_the_festival_cta_button_link}
                color="brand.tertiary"
                invert={true}
              />
            )}
        </>
      </ThemeProvider>
    </>
  );
};

export default FestivalAtPage;

export const query = graphql`
  query($id: String!) {
    festival: prismicFestival(id: { eq: $id }) {
      prismicId
      uid
      dataString
    }
  }
`;
