import React from "react";
import styled from "styled-components";
import { RichText as PrismicRichText } from "prismic-reactjs";
import { rgba } from "polished";

import { linkResolver, serializer } from "src/utils";

import {
  Box,
  FlexGrid,
  H2,
  SectionWrapper,
  RichText,
  Text,
} from "src/components";

const Inner = styled.div`
  height: calc(100% - 2px);
  margin: 1px;
  padding: 32px;
  text-align: center;
  background-color: ${(props) => props.theme.colors.bg.default};
`;

const Areas = ({ areas, heading }) => (
  <>
    <SectionWrapper
      id={heading.replace(/\s/g, "-").toLowerCase()}
      py={[8, 9, 10]}
      bg="bg.default"
    >
      <H2
        children={heading}
        mb={[6, 7, 8]}
        textAlign="center"
        color="text.default"
      />
      {areas && (
        <Box mx={["-7.5vw", 0]}>
          <FlexGrid>
            {areas.map((area, index) => (
              <Box
                // TEMP CHANGE FOR SUMMER SERENADES
                width={[1, 1 / 1]}
                key={"areaOfInterest" + index}
                css={`
                  position: relative;
                  background-color: ${(props) =>
                    rgba(props.theme.colors.bg.reverse, 0.125)};
                  // TEMP CHANGE FOR SUMMER SERENADES
                  width: 100%;
                  /* &:first-of-type {
                    width: 100%;
                  }
                  &:last-of-type:nth-of-type(even) {
                    width: 100%;
                  } */
                `}
              >
                <Inner>
                  {area.area_logo && area.area_logo.url && (
                    <img
                      src={area.area_logo.url}
                      height="256px"
                      width="auto"
                      alt={area.area_logo.alt}
                    />
                  )}
                  <Text
                    fontSize={[1, 2]}
                    mt={3}
                    mb={3}
                    fontWeight={600}
                    textAlign="center"
                    color="text.default"
                  >
                    {area.activation}
                  </Text>
                  <Box maxWidth={420} mx="auto">
                    {area.description && (
                      <RichText
                        children={
                          area.description &&
                          PrismicRichText.render(
                            area.description,
                            linkResolver,
                            serializer
                          )
                        }
                        fontSize={[1]}
                        color="text.default"
                      />
                    )}
                  </Box>
                </Inner>
              </Box>
            ))}
          </FlexGrid>
        </Box>
      )}
    </SectionWrapper>
  </>
);

export default Areas;
