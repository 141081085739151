import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { rgba } from "polished";

import {
  Box,
  FlexCol,
  FlexGrid,
  H6,
  RadioButton,
  ScheduleTable,
  SectionHeader,
  SectionWrapper,
  Text,
} from "src/components";

function Vendors({ heading, subtext, times, vendors }) {
  const [filter, setFilter] = useState("all");
  const [filteredVendors, setFilteredVendors] = useState([]);
  const [sponsoredVendors, setSponsoredVendors] = useState([]);
  const [options, setOptions] = useState([]);

  let formattedItems = [];
  if (times) {
    times.forEach((time) => {
      formattedItems.push({
        label: moment(time.day).format("dddd, MMMM D"),
        ...time,
      });
    });
  }

  useEffect(() => {
    if (vendors) {
      let optionsFound = [];
      vendors.forEach((vendor) => {
        if (vendor.items) {
          vendor.items.forEach((item) => {
            if (optionsFound.indexOf(item.food_category) === -1) {
              optionsFound.push(item.food_category);
            }
          });
        }
      });
      let finalOptions = [{ label: "All", value: "all" }];
      optionsFound.forEach((option) => {
        finalOptions.push({
          label: option,
          value: option.toLowerCase(),
        });
      });
      setOptions(finalOptions);
    }
  }, [vendors]);

  useEffect(() => {
    let newFilteredVendors = [];
    let newSponsoredVendors = [];
    if (vendors) {
      vendors.forEach((vendor) => {
        if (filter !== "all") {
          if (vendor.items) {
            vendor.items.forEach((item) => {
              if (item.food_category.toLowerCase() === filter) {
                if (vendor.primary.logo.url) {
                  newSponsoredVendors.push(vendor);
                } else {
                  newFilteredVendors.push(vendor);
                }
              }
            });
          }
        } else {
          if (vendor.primary.logo.url) {
            newSponsoredVendors.push(vendor);
          } else {
            newFilteredVendors.push(vendor);
          }
        }
      });
    }
    newSponsoredVendors = newSponsoredVendors.sort((a, b) => {
      if (
        (a.primary.vendor_name && a.primary.vendor_name.replace("The ", "")) <
        (b.primary.vendor_name && b.primary.vendor_name.replace("The ", ""))
      )
        return -1;
      if (
        (a.primary.vendor_name && a.primary.vendor_name.replace("The ", "")) >
        (b.primary.vendor_name && b.primary.vendor_name.replace("The ", ""))
      )
        return 1;
      return 0;
    });
    newFilteredVendors = newFilteredVendors.sort((a, b) => {
      if (
        (a.primary.vendor_name && a.primary.vendor_name.replace("The ", "")) <
        (b.primary.vendor_name && b.primary.vendor_name.replace("The ", ""))
      )
        return -1;
      if (
        (a.primary.vendor_name && a.primary.vendor_name.replace("The ", "")) >
        (b.primary.vendor_name && b.primary.vendor_name.replace("The ", ""))
      )
        return 1;
      return 0;
    });
    setSponsoredVendors(newSponsoredVendors);
    setFilteredVendors(newFilteredVendors);
  }, [filter]);

  return (
    <>
      <SectionWrapper
        id={heading.replace(/\s/g, "-").toLowerCase()}
        py={[8, 9, 10]}
        bg="bg.default"
        css={`
          border-top: 1px solid ${(props) => props.theme.colors.bg.alt};
        `}
      >
        <SectionHeader heading={heading} body={subtext} />
        <div
          css={`
            margin-bottom: ${(props) => props.theme.space[5] + "px"};
          `}
        >
          {options && options.length > 0 && (
            <RadioButton
              options={options}
              value={filter}
              valueProp="value"
              labelProp="label"
              onChange={setFilter}
            />
          )}
        </div>
        {sponsoredVendors && (
          <FlexGrid gutterX={[1]} gutterY={[1]}>
            {sponsoredVendors.map((vendor, index) => (
              <FlexCol
                key={"sponsorVendor" + vendor.primary.vendor_name + index}
                width={[1 / 2, 1 / 3, 1 / 4]}
              >
                {vendor.primary.logo && vendor.primary.logo.url && (
                  <div
                    css={`
                      position: relative;
                      padding-bottom: 66%;
                      width: 100%;
                    `}
                  >
                    <div
                      css={`
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                      `}
                    >
                      <div
                        css={`
                          height: 100%;
                          width: 100%;
                          background-image: ${"url(" +
                          vendor.primary.logo.url +
                          ")"};
                          background-size: cover;
                          background-repeat: no-repeat;
                          background-position: center;
                        `}
                      />
                    </div>
                  </div>
                  // <div
                  // css={`
                  //   display: block;
                  //   background-color: ${props =>
                  //     rgba(props.theme.colors.bg.reverse, 1 / 20)};
                  //   overflow: hidden;
                  // `}
                  // >
                  //   <img
                  //     src={vendor.primary.logo.url}
                  //     alt={vendor.primary.logo.url}
                  //     width="100%"
                  //     height="auto"
                  //   />
                  // </div>
                )}
                <div
                  css={`
                    height: 100%;
                    padding: ${(props) => props.theme.space[3] + "px"};
                    background-color: ${(props) =>
                      rgba(props.theme.colors.bg.reverse, 1 / 20)};
                  `}
                >
                  <Box mt={2}>
                    <Text
                      as={
                        vendor.primary.vendor_website &&
                        vendor.primary.vendor_website.url &&
                        "a"
                      }
                      href={
                        vendor.primary.vendor_website &&
                        vendor.primary.vendor_website.url &&
                        vendor.primary.vendor_website.url
                      }
                      target={
                        vendor.primary.vendor_website &&
                        vendor.primary.vendor_website.url &&
                        "_blank"
                      }
                      rel={
                        vendor.primary.vendor_website &&
                        vendor.primary.vendor_website.url &&
                        "noopener noreferrer"
                      }
                      fontWeight={600}
                    >
                      {vendor.primary.vendor_name}
                    </Text>
                    {vendor.primary.vendor_description && (
                      <Text>{vendor.primary.vendor_description}</Text>
                    )}
                    {vendor.primary.vendor_address && (
                      <Box mt={3}>
                        <H6
                          as={"a"}
                          href={
                            "http://maps.google.com/?q=" +
                            vendor.primary.vendor_address
                          }
                          target={"_blank"}
                          rel={"noopener noreferrer"}
                        >
                          {vendor.primary.vendor_address}
                        </H6>
                      </Box>
                    )}
                  </Box>
                </div>
              </FlexCol>
            ))}
          </FlexGrid>
        )}
        {filteredVendors && (
          <FlexGrid gutterX={[1]} gutterY={[1]}>
            {filteredVendors.map((vendor, index) => (
              <FlexCol
                key={"vendor" + vendor.primary.vendor_name + index}
                width={[1 / 2, 1 / 3, 1 / 4]}
              >
                <div
                  css={`
                    height: 100%;
                    padding: ${(props) => props.theme.space[3] + "px"};
                    background-color: ${(props) =>
                      rgba(props.theme.colors.bg.reverse, 1 / 20)};
                  `}
                >
                  <Text
                    as={
                      vendor.primary.vendor_website &&
                      vendor.primary.vendor_website.url &&
                      "a"
                    }
                    href={
                      vendor.primary.vendor_website &&
                      vendor.primary.vendor_website.url &&
                      vendor.primary.vendor_website.url
                    }
                    target={
                      vendor.primary.vendor_website &&
                      vendor.primary.vendor_website.url &&
                      "_blank"
                    }
                    rel={
                      vendor.primary.vendor_website &&
                      vendor.primary.vendor_website.url &&
                      "noopener noreferrer"
                    }
                    fontWeight={600}
                  >
                    {vendor.primary.vendor_name}
                  </Text>
                  {vendor.primary.vendor_description && (
                    <Text>{vendor.primary.vendor_description}</Text>
                  )}
                  {vendor.primary.vendor_address && (
                    <Box mt={3}>
                      <H6
                        as={"a"}
                        href={
                          "http://maps.google.com/?q=" +
                          vendor.primary.vendor_address
                        }
                        target={"_blank"}
                        rel={"noopener noreferrer"}
                      >
                        {vendor.primary.vendor_address}
                      </H6>
                    </Box>
                  )}
                </div>
              </FlexCol>
            ))}
          </FlexGrid>
        )}
        {formattedItems && formattedItems[0] && (
          <div
            css={`
              margin-top: ${(props) => props.theme.space[6] + "px"};
            `}
          >
            <ScheduleTable
              heading={"Food Concession Hours"}
              items={formattedItems}
            />
          </div>
        )}
      </SectionWrapper>
    </>
  );
}

export default Vendors;
